import clsx from 'clsx';

import { CircleSpinner } from '@/components/circle-spinner/CircleSpinner.component';

import styles from './SubmitButton.module.scss';

interface FormProps extends React.FormHTMLAttributes<HTMLButtonElement> {
    className?: string;
    formStatus: 'idle' | 'pending' | 'success' | 'failure';
}

export const SubmitButton = ({ className, children, formStatus, ...props }: FormProps) => {
    return (
        <>
            <button
                aria-disabled={formStatus === 'pending'}
                type="submit"
                className={clsx(styles.submitButton, className)}
                {...props}
            >
                {formStatus === 'pending' && <CircleSpinner />}
                <span>{children}</span>
            </button>
        </>
    );
};
