import cx from 'clsx';
import { useState } from 'react';

import { Button } from '@/components/button/Button.component';

import styles from '../Forms.module.scss';

export const ItemSelector = ({
    items,
    callback,
    className = '',
    disabled = false,
}: {
    items: string[];
    callback: (item: string) => void;
    className?: string;
    disabled?: boolean;
}) => {
    const [selected, setSelected] = useState(items.length === 1 ? items[0] : '');

    const handleAdd = () => {
        if (selected === '') return;
        callback(selected);
    };

    return (
        <fieldset className={cx(styles.add, className)} disabled={disabled}>
            <select defaultValue={selected} onChange={(e) => setSelected(e.target.value)}>
                {items.length !== 1 && (
                    <option value="" disabled>
                        Add a section
                    </option>
                )}
                {items.map((item) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </select>
            <Button kind="secondary" className={styles.addButton} onClick={handleAdd}>
                Add
            </Button>
        </fieldset>
    );
};
