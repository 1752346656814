import { ErrorMessage } from '@hookform/error-message';
import cx from 'clsx';
import { FieldErrors } from 'react-hook-form';

import ErrorCircle from '@/assets/images/error-circle.svg';

import styles from '../Forms.module.scss';

interface FieldErrorType {
    errors: FieldErrors;
    id: string;
    className?: string;
}

export const FieldError = ({ errors, id, className }: FieldErrorType) => {
    return (
        <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
                <span className={cx(styles.error, className)}>
                    <ErrorCircle className={styles.errorCircle} />
                    {message}
                </span>
            )}
        />
    );
};
