import cx from 'clsx';

import styles from '../Forms.module.scss';

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
    children: string | JSX.Element | (string | JSX.Element)[];
    className?: string;
}

export const Form = ({ children, className, ...props }: FormProps) => {
    return (
        <form className={cx(styles.form, className)} {...props}>
            {children}
        </form>
    );
};
