'use client';

import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import SuccessCircle from '@/assets/images/success-circle.svg';
import { FieldError, Form, Input, Label } from '@/components/forms';
import { SubmitButton } from '@/components/forms/submit-button/SubmitButton.component';
import { TextInputLabel } from '@/components/forms/text-input-label/TestInputLabel.component';
import { OIDC_CLIENT_ID, SUBSCRIBE_URL } from '@/constants';
import {
    defaultLanguage,
    defaultLanguages,
} from '@/features/site-language/helpers/defaultLanguages';

import { SubscribeFormType } from '../../types';

import styles from './SubscribeForm.module.scss';

type statusType = 'idle' | 'pending' | 'success' | 'failure';

const initFormValues = {
    dob: '',
    email: '',
    legal: false,
    marketing: false,
};

const oldEnough = (date: string) => {
    if (date === '') return false;

    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    const isOldEnough = new Date(date) < minDate;

    return isOldEnough;
};

const replaceWithLink = (string: string, href: string) => {
    const linkText = string.replace(/\{\{(.*?)\}\}/, (_, p1) => {
        return `<a href=${href} target="_blank" rel="nofollow noopener noreferrer">${p1}</a>`;
    });

    return linkText;
};

export const SubscribeForm = ({
    subscribeForm,
    lang = 'en',
}: {
    subscribeForm: SubscribeFormType;
    lang?: string;
}) => {
    const [formStatus, setFormStatus] = useState<statusType>('idle');
    const language =
        defaultLanguages.find((l) => l.iso.toLowerCase() === lang.toLowerCase()) || defaultLanguage;

    const privacyLink = language.iso === 'ko-KR' ? '/pages/newsletter-privacy' : '/pages/privacy';

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
        watch,
        setError,
        trigger,
    } = useForm({
        mode: 'onTouched',
        defaultValues: initFormValues,
    });

    const watchEmail = watch('email');
    const watchLegal = watch('legal');
    const watchMarketing = watch('marketing');

    const today = useMemo(() => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    }, []);

    const submitHandler: SubmitHandler<FieldValues> = async (formData, event) => {
        event?.preventDefault();
        if (formStatus !== 'idle') return;

        setFormStatus('pending');

        const body = JSON.stringify({
            ClientID: OIDC_CLIENT_ID as string,
            GUID: '',
            Email: formData.email,
            EventName: 'Redacted Sign Up',
            DateOfBirth: `${new Date(formData.dob).getTime() / 1000}`,
            Language: language.iso,
        });

        try {
            const response = await fetch(SUBSCRIBE_URL || '', {
                method: 'POST',
                body,
            });

            if (response.ok && response.status === 200) {
                setFormStatus('success');
                reset(initFormValues, { keepIsSubmitted: true });
            } else {
                setFormStatus('failure');
                setError('root', {
                    type: 'manual',
                    message: subscribeForm.generalFormError,
                });
            }
        } catch (error: any) {
            setFormStatus('failure');
            setError('root', {
                type: 'manual',
                message: subscribeForm.generalFormError,
            });
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (formStatus === 'success' || formStatus === 'failure') {
            timer = setTimeout(() => setFormStatus('idle'), 5000);
        }

        return () => clearTimeout(timer);
    }, [formStatus]);

    return (
        <Form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
            <div className={styles.dobWrapper}>
                <Label htmlFor="dob" className={styles.dobLabel}>
                    {subscribeForm.dobLabel}
                </Label>
                <Input
                    type="date"
                    className={clsx({ [styles.error]: errors.dob })}
                    aria-label="dob"
                    {...register('dob', {
                        required: {
                            value: true,
                            message: subscribeForm.dobError,
                        },
                        min: {
                            value: '1900-01-01',
                            message: subscribeForm.dobError,
                        },
                        max: {
                            value: today,
                            message: subscribeForm.dobError,
                        },
                        validate: (date: string) => oldEnough(date) || subscribeForm.dobError,
                    })}
                />

                <FieldError errors={errors} id="dob" />
            </div>
            <div className={styles.legalWrapper}>
                <div
                    className={clsx(styles.legal, {
                        [styles.checkboxError]: errors.legal,
                    })}
                >
                    <Input
                        type="checkbox"
                        className={clsx({ [styles.error]: errors.legal })}
                        {...register('legal', {
                            required: subscribeForm.tosError,
                            onChange: () => trigger('legal'),
                        })}
                        aria-label="legal terms checkbox"
                        checked={watchLegal}
                    />

                    <span
                        className={clsx({ [styles.boldLink]: language.iso === 'ko-KR' })}
                        dangerouslySetInnerHTML={{
                            __html: replaceWithLink(subscribeForm.tosCopy, privacyLink),
                        }}
                    ></span>
                    <FieldError className={styles.inlineError} errors={errors} id="legal" />
                </div>
            </div>
            <div className={styles.marketingWrapper}>
                <div
                    className={clsx(styles.marketing, {
                        [styles.checkboxError]: errors.marketing,
                    })}
                >
                    <Input
                        type="checkbox"
                        className={clsx({ [styles.error]: errors.marketing })}
                        {...register('marketing', {
                            required: subscribeForm.marketingAcceptanceError,
                            onChange: () => trigger('marketing'),
                        })}
                        aria-label="legal terms checkbox"
                        checked={watchMarketing}
                    />
                    <span
                        className={styles.marketingCopy}
                        dangerouslySetInnerHTML={{
                            __html: replaceWithLink(
                                subscribeForm.marketingAcceptanceCopy,
                                '/pages/tos'
                            ),
                        }}
                    ></span>
                    <FieldError className={styles.inlineError} errors={errors} id="marketing" />
                </div>
            </div>
            <div className={clsx(styles.titleContainer, styles.email)}>
                <TextInputLabel
                    id="email"
                    value={!!watchEmail}
                    label={subscribeForm.emailInputLabel}
                    errors={errors}
                >
                    <Input
                        className={clsx({
                            [styles.error]: errors.email,
                        })}
                        {...register('email', {
                            required: subscribeForm.emailInputError,
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: subscribeForm.emailFormatError,
                            },
                        })}
                        type="email"
                        aria-label="email"
                    />
                </TextInputLabel>
            </div>
            <div className={styles.submitButton}>
                <SubmitButton formStatus={formStatus} id="subscribe">
                    {subscribeForm.submitBtnText}
                </SubmitButton>
            </div>

            {formStatus === 'success' ? (
                <span className={styles.successMessage}>
                    <SuccessCircle className={styles.successCircle} />
                    {subscribeForm.formSuccessMessage}
                </span>
            ) : (
                ''
            )}
            <FieldError className={styles.errors} errors={errors} id="root" />
        </Form>
    );
};
