'use client';

import clsx from 'clsx';
import Fade from 'embla-carousel-fade';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';

import BottomTear from '@/assets/images/hero-bottom-tear.svg';
import { Video } from '@/components/video/Video.component';

import { MediaCarouselItem, MediaCarouselType } from '../../types';

import styles from './HeroHeader.module.scss';

const LoopedVideo = ({ url }: { url: string }) => {
    return (
        <Video
            className={styles.video}
            url={url}
            playing={true}
            loop={true}
            style={{ pointerEvents: 'none' }}
            controls={false}
        />
    );
};

const PlayIconContainer = () => {
    return (
        <div className={styles.playContainer}>
            <div className={styles.frostedGlass} />
            <span className={styles.play} />
        </div>
    );
};

export const HeroHeader = ({ mediaCarousel }: { mediaCarousel: MediaCarouselType }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [playing, setIsPlaying] = useState(false);
    const [thumbnailActive, setIsThumbnailActive] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [Fade()]);
    const [thumbsRef, thumbsApi] = useEmblaCarousel({
        containScroll: 'keepSnaps',
        dragFree: true,
    });

    const thumbnailClickHandler = useCallback(
        (id: number) => {
            if (!emblaApi || !thumbsApi) return;
            emblaApi.scrollTo(id);
            setIsThumbnailActive(false);
        },
        [emblaApi, thumbsApi]
    );

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setSelectedIndex(emblaApi.selectedScrollSnap());

        if (!thumbsApi) return;
        thumbsApi.scrollTo(emblaApi.selectedScrollSnap());
    }, [emblaApi, thumbsApi, setSelectedIndex]);

    useEffect(() => {
        if (!emblaApi) return;
        onSelect();
        emblaApi.on('select', onSelect);
        emblaApi.on('reInit', onSelect);
    }, [emblaApi, onSelect]);

    useEffect(() => {
        if (mediaCarousel.carouselItems) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [mediaCarousel.carouselItems]);

    return (
        <section className={styles.heroHeader}>
            <div className={styles.videoContainer}>
                <div className={styles.slider} ref={emblaRef}>
                    <div className={styles.sliderContainer}>
                        {isLoading ? (
                            <div className={styles.loader} />
                        ) : (
                            mediaCarousel.carouselItems?.map(
                                (carouselItem: MediaCarouselItem, index: number) => (
                                    <div key={carouselItem.id}>
                                        {carouselItem.video ? (
                                            thumbnailActive && index === 0 ? (
                                                <Video
                                                    className={styles.video}
                                                    url={carouselItem.video.url || ''}
                                                    light={
                                                        <LoopedVideo
                                                            url={mediaCarousel.loadedVideo.url}
                                                        />
                                                    }
                                                    playing={playing}
                                                    onClickPreview={() => {
                                                        setIsThumbnailActive(false);
                                                        setIsPlaying(true);
                                                    }}
                                                    playIcon={<PlayIconContainer />}
                                                    controls
                                                    muted={false}
                                                />
                                            ) : (
                                                <Video
                                                    url={carouselItem.video.url || ''}
                                                    playing={index === selectedIndex}
                                                    muted={false}
                                                />
                                            )
                                        ) : (
                                            carouselItem.image && (
                                                <Image
                                                    src={carouselItem.image.src}
                                                    alt={carouselItem.image.alt || 'Carousel Image'}
                                                    fill
                                                    loading="lazy"
                                                />
                                            )
                                        )}
                                    </div>
                                )
                            )
                        )}
                    </div>
                </div>

                {thumbnailActive && (
                    <>
                        <BottomTear className={clsx(styles.tearEffect, styles.bottomTear)} />
                    </>
                )}
            </div>

            <div className={styles.thumbsViewport} ref={thumbsRef}>
                <div className={styles.thumbsContainer}>
                    {mediaCarousel.carouselItems?.map(
                        (carouselItem: MediaCarouselItem, index: number) => {
                            const thumbnailSrc =
                                carouselItem.image?.src || carouselItem.video?.thumbnail.src || '';

                            return (
                                <button
                                    key={`carousel-item-${carouselItem.id}`}
                                    className={clsx(styles.itemFrame, {
                                        [styles.active]: index === selectedIndex,
                                    })}
                                    onClick={() => thumbnailClickHandler(index)}
                                    data-testid="media-button"
                                >
                                    {index < 4 ? (
                                        <>
                                            <Image
                                                className={styles.thumbnail}
                                                src={thumbnailSrc}
                                                alt={`Slideshow toggle ${carouselItem.id}`}
                                                width={320}
                                                height={180}
                                                data-testid={`media-thumbnail-${carouselItem.id}`}
                                                priority
                                                sizes="320px"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Image
                                                className={styles.thumbnail}
                                                src={thumbnailSrc}
                                                alt={`Slideshow toggle ${carouselItem.id}`}
                                                width={320}
                                                height={180}
                                                data-testid={`media-thumbnail-${carouselItem.id}`}
                                                sizes="320px"
                                            />
                                        </>
                                    )}

                                    <Image
                                        className={clsx(styles.imageFrame, {
                                            [styles.active]: index == selectedIndex,
                                        })}
                                        src={'/assets/images/red-frame.png'}
                                        fill
                                        alt=""
                                        role="presentation"
                                        sizes="25vw"
                                        data-testid={`media-thumbnail-red-${carouselItem.id}`}
                                    />
                                </button>
                            );
                        }
                    )}
                </div>
            </div>
        </section>
    );
};
