import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { FieldErrors } from 'react-hook-form';

import { FieldError } from '../field-error/FieldError.component';

import styles from './TextInputLabel.module.scss';

type TextInputLabelProps = {
    id: string;
    label?: string;
    // value used for styling label effect
    value?: boolean;
    errors: FieldErrors;
    className?: string;
};

export const TextInputLabel = ({
    id,
    label,
    value,
    errors,
    className,
    children,
}: PropsWithChildren<TextInputLabelProps>) => {
    return (
        <>
            <div className={clsx(styles.titleContainer, className)}>
                <div className={styles.labelContainer}>
                    {label && (
                        <label
                            className={clsx(styles.label, {
                                [styles.valid]: value,
                            })}
                        >
                            {label}
                        </label>
                    )}
                    {children}
                </div>
            </div>
            <FieldError className={styles.fieldError} errors={errors} id={id} />
        </>
    );
};
