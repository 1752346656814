import cx from 'clsx';
import { useState } from 'react';

import styles from '../Forms.module.scss';

interface TextareaType {
    className?: string;
    callback?: any;
    value?: string;
    maxRows?: number;
    minRows?: number;
    name?: string;
    register?: any;
}

export const Textarea = ({
    className,
    callback,
    value,
    maxRows,
    minRows = 1,
    register,
    name,
    ...props
}: TextareaType) => {
    const [rows, setRows] = useState(minRows);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const styles = window.getComputedStyle(event.target);
        const lineHeight = parseInt(styles.getPropertyValue('line-height')) || 1;

        const previousRows = event.target.rows;

        event.target.rows = minRows;

        const height =
            event.target.scrollHeight -
            parseInt(styles.getPropertyValue('padding-top')) -
            parseInt(styles.getPropertyValue('padding-bottom'));

        const currentRows = Math.floor(height / lineHeight);

        if (currentRows === previousRows) event.target.rows = currentRows;

        if (maxRows && currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = height;
        }

        setRows(maxRows && currentRows > maxRows ? maxRows : currentRows);
        callback(event);
    };

    return (
        <textarea
            className={cx(styles.textarea, className)}
            rows={rows}
            {...(register && name
                ? register(name, { onChange: handleChange })
                : { onChange: handleChange })}
            {...(value && { value: value })}
            {...props}
        />
    );
};
