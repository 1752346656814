'use client';

import Image from 'next/image';

import TearEffect from '@/assets/images/tear-effect.svg';
import { PaperBox } from '@/components/paper-box/PaperBox.component';
import { TextBlock } from '@/components/text-block/TextBlock.component';

import { SubscribeSectionType } from '../../types';
import { SubscribeForm } from '../subscribe-form/SubscribeForm.component';

import styles from './SubscribeSection.module.scss';

export const SubscribeSection = ({
    subscribeSection,
    lang = 'en',
}: {
    subscribeSection: SubscribeSectionType;
    lang?: string;
}) => {
    return (
        <section id="newsletter-section" className={styles.userActionSection}>
            <div className={styles.backgroundWrapper}>
                <div className={styles.layout}>
                    <div className={styles.actions}>
                        <PaperBox title={subscribeSection?.title}>
                            <TextBlock
                                className={styles.paperContent}
                                content={subscribeSection?.copy}
                            />

                            <SubscribeForm subscribeForm={subscribeSection?.form} lang={lang} />
                        </PaperBox>
                    </div>
                </div>
            </div>
            <Image
                className={styles.backgroundImage}
                src={subscribeSection?.backgroundImage.src}
                alt="newsletter background"
                fill
            />
            <TearEffect className={styles.tearEffect} />
        </section>
    );
};
