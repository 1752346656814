'use client';

import cx from 'clsx';
import Image from 'next/image';
import { Fragment, useState } from 'react';

import { FaqsType } from '../../types';

import styles from './Faqs.module.scss';

export const Faqs = ({
    faqList,
    className,
    allowMultipleOpen = true,
    openFirstPanel = false,
    expand = '',
    collapse = '',
}: {
    faqList: FaqsType[];
    expand: string;
    collapse: string;
    allowMultipleOpen?: boolean;
    openFirstPanel?: boolean;
    className?: string;
}) => {
    const setInitialOpen = () => {
        if (openFirstPanel) {
            return [`faq-${faqList[0].id}`];
        } else {
            return [];
        }
    };

    const [openGroups, setOpenGroups] = useState<string[]>(setInitialOpen());

    const handleClick = (id: string) => {
        let newGroups = [...openGroups];

        if (newGroups.includes(id)) {
            newGroups = newGroups.filter((group) => group !== id);
        } else {
            newGroups = allowMultipleOpen ? [...newGroups, id] : [id];
        }

        setOpenGroups(newGroups);
    };

    return (
        <section className={cx(styles.accordion, className)}>
            <div className={styles.inner}>
                {faqList.map((faqItem) => {
                    const id = `faq-${faqItem.id}`;

                    return (
                        <Fragment key={id}>
                            <div className={styles.borderWrapper}>
                                <h3 className={styles.title}>
                                    <button
                                        aria-controls={`${id}-panel`}
                                        aria-expanded={openGroups.includes(id)}
                                        className={styles.button}
                                        id={`${id}-title`}
                                        onClick={() => handleClick(id)}
                                        type="button"
                                        title={openGroups.includes(id) ? collapse : expand}
                                    >
                                        <h6>{faqItem.question}</h6>
                                        {openGroups.includes(id) ? (
                                            <Image
                                                className={styles.paper}
                                                src="/assets/images/minus.svg"
                                                alt=""
                                                role="presentation"
                                                width={32}
                                                height={32}
                                            />
                                        ) : (
                                            <Image
                                                className={styles.paper}
                                                src="/assets/images/plus.svg"
                                                alt=""
                                                role="presentation"
                                                width={32}
                                                height={32}
                                            />
                                        )}
                                    </button>
                                </h3>
                                <div
                                    className={cx(styles.collapse, {
                                        [styles.active]: openGroups.includes(id),
                                    })}
                                    aria-labelledby={`${id}-title`}
                                    id={`${id}-panel`}
                                    role="region"
                                    hidden={!openGroups.includes(id)}
                                >
                                    <div className={styles.collapseInner}>
                                        <p>{faqItem.answer}</p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </section>
    );
};
