import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/paper-bg.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/redacted-black.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/skull.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/tape-bg.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/tear-effect.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/paper-box/PaperBox.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/app/src/components/video/Video.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/features/authentication/context/Session.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMEvent"] */ "/app/src/features/google-tag-manager/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/home/components/faqs-section/FaqsSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Faqs"] */ "/app/src/features/home/components/faqs/Faqs.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroHeader"] */ "/app/src/features/home/components/hero-header/HeroHeader.component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/home/components/key-features/KeyFeatures.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/home/components/leaderboards-section/LeaderboardsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/home/components/preorder/Preorder.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/home/components/split-media/SplitMedia.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SubscribeSection"] */ "/app/src/features/home/components/subscribe-section/SubscribeSection.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommunityStat"] */ "/app/src/features/leaderboards/components/communityStat/CommunityStat.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeaderboardsHeader"] */ "/app/src/features/leaderboards/components/header/LeaderboardsHeader.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Leaderboard"] */ "/app/src/features/leaderboards/components/leaderboard/Leaderboard.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginCTA"] */ "/app/src/features/leaderboards/components/leaderboard/LoginCTA.component.tsx");
