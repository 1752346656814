import cx from 'clsx';
import { forwardRef, useRef } from 'react';

import styles from '../Forms.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function InputFn(
    { className, ...props },
    ref
) {
    const localRef = useRef(null);
    const inputRef = ref || localRef;
    return <input className={cx(styles.input, className)} ref={inputRef} {...props} />;
});
