import cx from 'clsx';

import styles from '../Forms.module.scss';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    children: string | JSX.Element | (string | JSX.Element)[];
    wrapper?: boolean;
    className?: string;
}

export const Label = ({ children, wrapper, className, ...props }: LabelProps) => {
    return (
        <label className={cx(wrapper ? styles.field : styles.label, className)} {...props}>
            {children}
        </label>
    );
};
